@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;

    &__logo-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__logo {
        width: 3.125rem;

        @include largeScreen {
            width: 6.25rem;
        }
    }

    &__list {
        display: none;

        @include tablet {
            flex: 1;
            @include flexCenter;
            list-style: none;
        }
    }

    &__item {
        margin: 0 1rem;
        cursor: pointer;
        @include flexCenter;
        flex-direction: column;

        &:hover {
            .navbar__dot {
                background: $secondary-colour;
            }
        }
    }

    &__dot {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
    }

    &__link {
        @include textStyle($gray-colour, 0.8rem, 1.5, 500);
        text-decoration: none;
        text-transform: uppercase;
        text-align: left;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $secondary-colour;
        }

        @include tablet {
            font-size: 1rem;
        }

        @include largeScreen {
            font-size: 1.75rem;
        }
    }
}

.navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    @include flexCenter;
    background: $secondary-colour;

    @include tablet {
        display: none;
    }

    &__icon {
        width: 70%;
        height: 70%;
        color: $white-colour;

        &--close {
            width: 35px;
            height: 35px;
            color: $secondary-colour;
            margin: 0.5rem 1rem;
        }
    }

    &__container {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 80%;
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        background: url("../../assets/images/bgWhite.png");
        background-color: $white-colour;
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__item {
        margin: 1rem;
    }

    &__link {
        @include textStyle($gray-colour, 1rem, 1, 500);
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $secondary-colour;
        }
    }
}