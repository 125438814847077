@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;


.social {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    padding: 1rem;

    &__item {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: $white-colour;
        margin: 0.25rem 0;
        border: 1px solid $lightGray-colour;
        @include flexCenter;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $secondary-colour;
            border-color: $secondary-colour;

            .social__icon {
            color: $white-colour;
            }
        }

        @include largeScreen {
            width: 4.375rem;
            height: 4.375rem;
            margin: 0.5rem 0;
        }
    }

    &__icon {
        width: 0.9375rem;
        height: 0.9375rem;
        color: $gray-colour;

        @include largeScreen {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
}