@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

#home {
    position: relative;
    background: url("../../assets/images/bgIMG.png");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
}

.header {
    flex: 1;
    @include flexCenter;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 6rem 2rem 0;

    @include firstMobile {
        padding: 6rem 1rem 2rem;
    }

    @include desktop {
        flex-direction: row;
    }

    @include largeScreen {
        padding-top: 8rem;
    }

    &__info {
        flex: 0.65;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;

        @include largeScreenMax {
            width: 100%;
            margin-right: 0rem;
        }
    }

    &__badge {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include desktop {
            justify-content: flex-end;
            align-items: flex-end; 
        }
    }

    &__badge-cmp, &__tag-cmp {
        @include flexCenter;
        padding: 1rem 2rem;
        border: $white-colour;
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__icon {
        font-size: 2.5rem;

        @include largeScreen {
            font-size: 5rem;
        }
    }

    &__intro-container {
        margin-left: 1.25rem;
    }

    &__intro {
        @include textStyle($gray-colour, 0.8rem, 1.5, 500);
        text-align: left;

        @include tablet {
            font-size: 1rem;
        }

        @include largeScreen {
            font-size: 1.75rem;
        }

        &--tag {
           width: 100%;
           text-transform: uppercase;
           text-align: right; 
        }
    }

    &__name {
        @include textStyle($black-colour, 2.75rem, 1.5, 800);
        text-align: center;
        text-transform: capitalize;

        @include firstMobile {
            font-size: 2rem;
        }

        @include largeScreen {
            font-size: 4rem;
        }
    }

    &__tag-cmp {
        flex-direction: column;
        margin-top: 3rem;
    }

    &__circles {
        flex: 0.75;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        height: 100%;
        margin-left: 1rem;

        @include desktopMax {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 0;
        }
    }

    &__circle-cmp {
        @include flexCenter;
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 50%;
        background: $white-colour;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        &:nth-child(1) {
            width: 6.25rem;
            height: 6.25rem;
        }

        &:nth-child(2) {
            width: 9.375rem;
            height: 9.375rem;
            margin: 1.75rem;
        }

        &:nth-child(3) {
            width: 4.375rem;
            height: 4.375rem;
        }

        @include largeScreen {
            &:nth-child(1) {
                width: 12.5rem;
                height: 12.5rem;
            }

            &:nth-child(2) {
                width: 18.75rem;
                height: 18.75rem;
            }

            &:nth-child(3) {
                width: 8.75rem;
                height: 8.75rem;
            }
        }

        @include desktopMax {
            margin: 1rem;
        }
    }

    &__circle-image {
        width: 60%;
        height: 60%;
    }

    &__image-container {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;

        @include desktopMax {
            margin: 2rem 0;
        }
    }

    &__image {
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }
    
    &__circle-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
    }
}