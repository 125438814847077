@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.experience {
    &__title {
        @include textStyle($black-colour, 2.75rem, 1.5, 800);
        text-align: center;
        text-transform: capitalize;

        @include firstMobile {
            font-size: 2rem;
        }

        @media only screen and (max-width: 1276px) {
            margin-top: 2rem;
        }

        @include largeScreen {
            font-size: 4rem;
        }
    }

    &__container {
        width: 80%;
        margin-top: 3rem;
        display: flex;
        flex-direction: row;

        @include desktopMax {
            width: 100%;
            flex-direction: column;
        }
    }

    &__list {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        @include tabletMax {
            margin-top: 2rem;
        }
    }

    &__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 1rem 0rem;
    }

    &__works-list {
        flex: 1;
    }

    &__works-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    &__year-container {
        width: 12rem;
        margin-right: 3rem;

        @include tabletMax {
            width: 6rem;
        }

        @include firstMobile {
            width: 5rem;
        }
    }

    &__year {
        @include textStyle($secondary-colour, 1rem, 1.5, 800);
        text-align: left;
        margin-top: 1rem;

        @include tablet {
            font-size: 1.125rem;
        }

        @include largeScreen {
            font-size: 2rem;
        }

        @include firstMobile {
            font-size: 0.9rem;
        }
    }

    &__works-name {
        @include textStyle($black-colour, 1rem, 1.5, 800);
        text-align: left;
        margin-top: 1rem;

        @include tablet {
            font-size: 1.125rem;
        }

        @include largeScreen {
            font-size: 2rem;
        }

        @include firstMobile {
            font-size: 0.9rem;
        }
    }

    &__works-company {
        margin-top: 0.3125rem;
        @include textStyle($gray-colour, 0.8rem, 1.5, 500);
        text-align: left;

        @include tablet {
            font-size: 1rem;
        }

        @include largeScreen {
            font-size: 1.75rem;
        }
    }

    &__tooltip {
        max-width: 18.75rem !important;
        background-color: $white-colour !important;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
        border-radius: 5px !important;
        padding: 1rem !important;
        color: $gray-colour !important;
        text-align: center !important;
        line-height: 1.5 !important;
        opacity: 1 !important;

        @include largeScreen {
            max-width: 31.25rem !important;
            font-size: 1.75rem !important;
            line-height: 2 !important;
        }
    }

    &__button-link {
        text-decoration: none;
    }

    &__button {
        @include flexCenter;
        height: 3.125rem;
        width: 12.5rem;
        @include textStyle($white-colour, 1.25rem, 0, 800);
        text-align: center;
        text-transform: uppercase;
        border: none;
        outline: none;
        background-image: linear-gradient(to right, #26a0da 0%, $secondary-colour  51%, #26a0da 100%);
        padding: 1rem 3rem;
        transition: 0.5s;
        background-size: 200% auto;       
        box-shadow: 0 0 20px $lightGray-colour;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            background-position: right center;
        }

        @include desktopMax {
            margin-bottom: 2rem;
        }
    }
}

.experience-skill {
    &__list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 5rem;

        @include desktopMax {
            margin-right: 0rem;
            justify-content: center;
            align-items: center;
        }
    }
    
    &__item {
        @include flexCenter;
        flex-direction: column;
        text-align: center;
        margin: 1rem;
        transition: all 0.3s ease-in-out;

        @include largeScreen {
            margin: 1rem 2rem;
        }
    }

    &__item-container {
        @include flexCenter;
        width: 5.625rem;
        height: 5.625rem;
        border-radius: 50%;
        background-color: $white-colour;

        @include largeScreen {
            width: 9.375rem;
            height: 9.375rem;
        }

        @include secondMobile {
            width: 4.375rem;
            height: 4.375rem;
        }

        &:hover {
            box-shadow: 0 0 25px #fef4f5;
        }
    }

    &__icon {
        width: 60%;
        height: 60%;
    }

    &__name {
        margin-top: 0.5rem;
        @include textStyle($gray-colour, 0.8rem, 1.5, 500);
        text-align: left;

        @include tablet {
            font-size: 1rem;
        }

        @include largeScreen {
            margin-top: 1rem;
            font-size: 1.75rem;
        }
    }
}