@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.projects {
    &__title {
        @include textStyle($black-colour, 2.75rem, 1.5, 800);
        text-align: center;
        text-transform: capitalize;

        @include firstMobile {
            font-size: 2rem;
        }

        @include largeScreen {
            font-size: 4rem;
        }

        &--highlight {
            color: $secondary-colour;
        }
    }

    &__portfolio {
        @include flexCenter;
        flex-direction: column;

        @include smallTablet {
            flex-direction: row;
            width: 100%;
            justify-content: space-around;
        }

        @include tablet {
            justify-content: center;
        }
    }

    &__item {
        @include flexCenter;
        flex-direction: column;
        width: 16.875rem;
        margin-top: 1rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: $primary-colour;
        color: #000;
        transition: all 0.3s ease;

        @include smallTablet {
            margin-right: 5rem;
        }

        @include largeScreen {
            width: 29.375rem;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }
    }

    &__image-container {
        @include flexCenter;
        width: 100%;
        height: 14.375rem;
        position: relative;

        @include largeScreen {
            height: 21.875rem;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        object-position: left;
    }

    &__hover {
        @include flexCenter;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
        opacity: 0;
        transition: all 0.3s ease;
    }

    &__icons-container {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    &__icon-container {
        @include flexCenter;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: $white-colour;
        cursor: pointer;
        transition: all 0.3 ease;
    }

    &__icon {
        width: 50%;
        height: 50%;
        color: $white-colour;
    }

    &__link {
        @include flexCenter;
        color: #000;
        text-decoration: none;
    }

    &__content {
        @include flexCenter;
        flex-direction: column;
        padding: 0.5rem;
        width: 100%;
        position: relative;

        &__title {
            @include textStyle($black-colour, 1rem, 1.5, 800);
            text-align: left;
            margin-top: 1rem;

            @include tablet {
                font-size: 1.125rem;
            }

            @include largeScreen {
                font-size: 2rem;
            }

            @include firstMobile {
                font-size: 0.9rem;
            }
        }

        &__description {
            margin-top: 0.625rem;
            @include textStyle($gray-colour, 0.8rem, 1.5, 500);
            text-align: left;

            @include tablet {
                font-size: 1rem;
            }

            @include largeScreen {
                font-size: 1.75rem;
            }
        }
    }

    &__item-right-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__link-container {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &--last {
            margin: 0;
        }
    }

    &__link-icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        cursor: pointer;
    }

    &__link-title {
        @include textStyle($black-colour, 1rem, 1.5, 800);
        cursor: pointer;

        @include tablet {
            font-size: 1.125rem;
        }
        
        @include largeScreen {
            font-size: 2rem;
        }

        @include firstMobile {
            font-size: 0.9rem;
        }
    }
}
