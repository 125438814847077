// Colour Variables
$primary-colour: #edf2f8;
$secondary-colour: #313bac;
$black-colour: #030303;
$lightGray-colour: #e4e4e4;
$gray-colour: #6b7688;
$brown-colour: #46364a;
$white-colour: #ffffff;

// Breakpoint Variables
$first-mobile-breakpoint: 28.125rem; //450px
$second-mobile-breakpoint: 31.25rem; //500px
$tablet-max-breakpoint: 48rem; //768px
$tablet-breakpoint: 56.25rem; //900px
$desktop-breakpoint: 75rem; //1200px
$large-screen-breakpoint: 125rem; //2000px

