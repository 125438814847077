@font-face {
    font-family: "DM Sans";
    src: url("../../assets/fonts/DMSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "DM Sans";
    src: url("../../assets/fonts/DMSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DM Sans";
    src: url("../../assets/fonts/DMSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}