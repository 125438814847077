@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.navigation-sidebar {
    @include flexCenter;
    flex-direction: column;
    padding: 1rem;
    overflow: hidden;

    @include firstMobile {
        padding-right: 0;
    }

    &__dot {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background-color: #cbcbcb;
        margin: 0.5rem;
        transition: background-color 0.2s ease-in-out;

        &--active {
            background-color: $secondary-colour;
        }

        &:hover {
            background-color: $secondary-colour;
        }

        @include largeScreen {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}