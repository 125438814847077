@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.about {
    &__title {
        @include textStyle($black-colour, 2.75rem, 1.5, 800);
        text-align: center;
        text-transform: capitalize;

        @include firstMobile {
            font-size: 2rem;
        }

        @media only screen and (max-width: 1145px) {
            margin-top: 2rem;
        }

        @include largeScreen {
            font-size: 4rem;
        }

        &--highlight {
            color: $secondary-colour;
        }
    }

    &__paragraph {
        margin-top: 1rem;
        width: 60%;
        @include textStyle($gray-colour, 0.8rem, 1.5, 500);
        text-align: left;

        @include secondMobile {
            width: 90%;
        }

        @include tabletMax {
            width: 75%;
        }

        @include tablet {
            font-size: 1rem;
        }

        @include largeScreen {
            font-size: 1.75rem;
        }
    }

    &__profiles {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    &__profile-item {
        width: 11.875rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 2rem;

        @include largeScreen {
            width: 23.125rem;
            margin: 2rem 4rem;
        }
    }

    &__profile-image {
        width: 100%;
        height: 10.625rem;
        border-radius: 15px;
        object-fit: cover;

        @include largeScreen {
            height: 20rem;
        }
    }

    &__profile-header {
        margin-top: 1.25rem;
        @include textStyle($black-colour, 1rem, 1.5, 800);
        text-align: left;

        @include tablet {
            font-size: 1.125rem;
        }

        @include largeScreen {
            font-size: 2rem;
        }

        @include firstMobile {
            font-size: 0.9rem;
        }
    }

    &__profile-text {
        margin-top: 0.625rem;
        @include textStyle($gray-colour, 0.8rem, 1.5, 500);
        text-align: left;

        @include tablet {
            font-size: 1rem;
        }
        
        @include largeScreen {
            font-size: 1.75rem;
        }
    }
}