@use "./variables" as *;

@mixin firstMobile {
    @media (max-width: $first-mobile-breakpoint) {
        @content;
    }
}

@mixin secondMobile {
    @media (max-width: $second-mobile-breakpoint) {
        @content;
    }
}

@mixin smallTablet {
    @media (min-width: $tablet-max-breakpoint) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin tabletMax {
    @media (max-width: $tablet-max-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin desktopMax {
    @media (max-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin largeScreen {
    @media (min-width: $large-screen-breakpoint) {
        @content;
    }
}

@mixin largeScreenMax {
    @media (max-width: $large-screen-breakpoint) {
        @content;
    }
}

@mixin textStyle ($colour, $font-size, $line-height, $font-weight) {
    color: $colour;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
}

@mixin flexCenter() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin appSection() {
    flex: 1;
    width: 100%;
    flex-direction: column;
}
